.email-verified-container {
    position: relative;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .container {
    position: relative;
    z-index: 1; /* Ensure content appears above the background image */
  }
  
  /* Add any additional styling as needed */
  