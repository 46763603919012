.flex-container {
    display: flex;
    justify-content: center;
  }
  
  @media (min-width: 576px) {
    .flex-container {
      justify-content: flex-start;
    }
    .flex-containerthree {
        justify-content: center;
      }
  }
  
  .image-responsive {
    max-width: 100%;
    height: auto;
  }
  .image-responsivetwo {
    /* max-width: 100%;
    height: auto; */
    max-width: 700px;
     height: 500px;
  }

  .image-responsiveOne{
    max-width: 700px;
    height: 400px;
  }
  .image-responsivethree {
    /* max-width: 100%;
    height: auto; */
    max-width: 350px;
     height: 360px;
  }
  @media (min-width: 576px) {
    .image-responsivethree {
      max-width: 460px;
      height: 460px;
    }
  }
  