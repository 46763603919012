.input-label {
  font-weight: 600;
  display: block;
  color: #000;
}

.input {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 8px 20px;
  width: 100%;
  margin: 0px !important;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  font-size: 14px;
  font-weight: 600;
}


