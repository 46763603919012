.move-btn:hover {
    background-color: "#222222" !important
}

#progress {
    pointer-events: none; 
  }
.steps {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
}
.step-button {
   width: 100%;
   height: 100%;
   color: #c54646;
  border: none;
  background-color: grey;
    transition: .4s;
}
.step-button[aria-expanded="true"] {
   
 background-color: rgb(1, 179, 13);

}
.step-button[aria-expanded="false"] {
   
    background: grey;
   
   }
.done {
    background-color: var(--prm-color);
    color: #fff;
}
.step-item {

width: 180px;
height: 8px;
    z-index: 100;
    text-align: center;
}
#progress {
  -webkit-appearance:none;
    position: absolute;
    width: 95%;
    z-index: 5;
    height: 10px;
    margin-left: 18px;
    margin-bottom: 18px;
}
/* to customize progress bar */
#progress::-webkit-progress-value {
    background-color: var(--prm-color);
    transition: .5s ease;
}
#progress::-webkit-progress-bar {
    background-color: var(--prm-gray);

}