.Main_Head{
    font-family: Poppins;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 30px;
    text-align: left;
}
.Main_subHead{
    font-size: 14px;
    font-weight: 400;
    color:#A3A3A3;
    padding-top: 8px;
    padding-bottom: 8px;
}
.Box-text{
font-size: 14px;
font-weight: 600;
color:#868E96;
}
.Box-subtext{
    font-size: 14px;
font-weight: 600;
color:#37474F;
}
.Box-subtext-box{
    color: #37474F;
    margin-top: 8px;
    margin-left: 2px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding-left: 8px;
}


.text{
    font-family: "Poppins";
font-size: 14px;
font-weight: 400;
line-height: 16px;
text-align: left;

}