.About-usHead {
  font-size: 90px;
  color: "#000000";
  font-weight: 500;
}
.About-ussubhead {
  font-size: 18px;
  color: "#222222";
  font-weight: 400;
}
.container-image {
  position: relative;
  text-align: left;
}

.container-box {
  position: relative;
  text-align: left;
  background-color: #f5f5f5;
  padding: 28px;
  height: 220px;
}
.about-us-box-other {
  background-color: #222222;
  padding: 28px;
}
.About-us-box {
  border: 1px solid #222222;
  padding: 28px;
}
.About-us-box-center {
  border: 1px solid #222222;
  padding: 28px;
  background-color: #222222;
  color: #ffff;
}
.bottom-left {
  position: absolute;
  top: 410px;
  left: 16px;
  padding: 28px;
  background-color: #e8bfbf;
  width: 529px;
  height: 300px;
}

.top-left {
  position: absolute;
  border: 1px solid black;
  top: -50px;
  background-color: #f5f5f5;
  padding: 8px;
}
.pic-width {
  width: 80px;
  height: 80px;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 1024px) {
  .container-image img{
   
    max-width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .container-image {
    position: relative;
    text-align: left;
    max-width: auto;
    height:60vh
  }
  .bottom-left {
    position: absolute;
    top: 110px;
    left: 16px;
    padding: 28px;
    background-color: #e8bfbf;
    max-width: 250px;
    /* width: 529px; */
    height: 357px;
}
  .container-image img{
   
    max-width: 250px;
  }
  .container-box {
    position: relative;
    text-align: center;
    background-color: #f5f5f5;
    padding: 28px;
    height: 100%;
  }
}
