.Main_Head{
    font-size: 20px;
    font-weight: 500;
    color:#525256 !important;
padding-top: 8px;
}
.Main_subHead{
    font-size: 14px;
    font-weight: 400;
    color:#A3A3A3;
    padding-top: 8px;
    padding-bottom: 8px;
}
.card_subtext{
    color:#B9B9C3;
    font-size:16px;
   font-weight:500
}
.card_subtext1{
    color:#222222;
    font-size:16px;
   font-weight:400
}
