.Main_Head{
    font-size: 20px;
    font-weight: 500;
    color:#525256 !important;
padding-top: 8px;
}
.Main_subHead{
    font-size: 14px;
    font-weight: 400;
    color:#A3A3A3;
    padding-top: 8px;
    padding-bottom: 8px;
}

.top-head {
    margin-top: 10px;
}

@media (max-width:900px) {
    .top-head {
        margin-top: 35px;
    }
}

@media (max-width:600px) {
    .top-head {
        margin-top: 55px;
    }
}